document.addEventListener('DOMContentLoaded', () => {
  const toggleArea = document.querySelector('.js-country-toggle');

  if (toggleArea) {
    const toggleDropdown = (arrowElement, dropdown) => {
      dropdown.classList.toggle('footer__country-dropdown--hidden');
      arrowElement.querySelector('.footer__country-icon').classList.toggle('footer__country-icon--up');
    };

    const dropdown = document.querySelector('.js-country-dropdown');

    toggleArea.addEventListener('click', (event) => {
      if (event.target.tagName.toLowerCase() === 'a') {
        return;
      }
      event.preventDefault();
      toggleDropdown(toggleArea, dropdown);
    });

    // click outside the dropdown handler
    document.addEventListener('click', (event) => {
      const isClickInside = dropdown.contains(event.target);
      const isDropdownOpen = dropdown.classList.contains('footer__country-dropdown')
        && !dropdown.classList.contains('footer__country-dropdown--hidden');
      const isToggleAreaClicked = toggleArea.contains(event.target);

      if (!isClickInside && isDropdownOpen && !isToggleAreaClicked) {
        toggleDropdown(toggleArea, dropdown);
      }
    });

    // Prevent redirection when clicking on country links
    document.querySelectorAll('.footer__country-link').forEach((link) => {
      link.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent the click event from bubbling up
      });
    });
  }
});
